/* Скрываем тултип в мобилке */
@media (max-width: 570px) {
  .ngx-charts-tooltip-content {
    display: none !important;
  }
}

.ngx-charts-tooltip-content.type-tooltip,
.ngx-charts-tooltip-content.position-top{
  border-radius: 10px !important;
  padding: 10px 12px !important;
  box-shadow: 0px 6px 30px 0px rgba(51, 58, 85, 0.3) !important;
  background: rgba(12, 12, 12, 0.7) !important;
  text-align: left !important;
}

.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-top{
  box-shadow: 0px 6px 30px 0px rgba(51, 58, 85, 0.3) !important;
  border-top: 7px solid rgba(12, 12, 12, 0.7) !important;
}

.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-right{
  border-right: 7px solid rgba(12, 12, 12, 0.7) !important;
}
.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-left {
  border-left: 7px solid rgba(12, 12, 12, 0.7) !important;
}