/*====== Font-size css starts ======*/
$i:10;
@while $i<=100 {
  .f-#{$i} {
    font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/
$i:100,
300,
400,
600,
700,
900;
@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}
/*====== Font-weight css ends ======*/

/*====== Height css starts ======*/
$i:12;
@while $i<=500 {
  .h-#{$i} {
    height:#{$i}px;
  }
  $i:$i+1;
}
/*====== Height css ends ======*/

/*====== Line-height css starts ======*/
$i:100;
@while $i<=1000 {
  .l-h-#{$i} {
    line-height: #{$i}+'%';
}
$i:$i+10;
}
/*====== Line-height css ends ======*/

/**=====================
    Mobile options
==========================**/
@media(max-width: 540px)
{

/*====== Mobile Font-size css starts ======*/
  $i:12;
  @while $i<=100 {
    .mf-#{$i} {
      font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Mobile Font-size css ends ======*/
}

/**=====================
    Tablet options
==========================**/
@media(min-width: 540px) and (max-width: 992px)
{

/*====== Tablet Font-size css starts ======*/
  $i:12;
  @while $i<=100 {
    .tf-#{$i} {
      font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Tablet Font-size css ends ======*/
}

@media(min-width: 992px)
{
/*====== Desktop large Font-size css starts ======*/
  $i:12;
  @while $i<=100 {
    .f-lg-#{$i} {
      font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Desktop large Font-size css ends ======*/
}

@media(min-width: 1200px)
{
/*====== Desktop xl Font-size css starts ======*/
  $i:12;
  @while $i<=100 {
    .f-xl-#{$i} {
      font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Desktop xl Font-size css ends ======*/
}

@media(min-width: 1400px)
{
/*====== Desktop xxl Font-size css starts ======*/
  $i:12;
  @while $i<=100 {
    .f-xxl-#{$i} {
      font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Desktop xxl Font-size css ends ======*/
}

/*====== Img size css starts ======*/
$i:10;
@while $i<=500 {
  .img-#{$i} {
    height:#{$i}px;
    width:#{$i}px;
  }
  $i:$i+1;
}
/*====== Img size css css ends ======*/