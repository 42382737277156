.bs-datepicker {
  border: 1px solid rgba(168, 168, 168, 0.2);
  //-webkit-backdrop-filter: blur(20px) !important;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.02);
  padding-top: 10px;
}

/* in order for it not to work in older versions of safari */
// @supports (backdrop-filter: blur(20px)) {
//   .bs-datepicker  {
//     backdrop-filter: blur(20px);
//   }
// }

.bs-datepicker-body table td {
  color:#fff;
}

.theme-green .bs-datepicker-head {
  background-color: inherit !important;
}

.bs-datepicker-body{
  border: inherit;
  padding: 10px;
  min-height: auto;
  min-width: 100%;
  max-width: 100%;
}

.bs-datepicker-head{
  min-width: 100%;
  max-width: 100%;
  height: auto;
  padding: 10px 0;
}

.bs-datepicker-body table.days span{
  width: 100%;
  height: auto;
}

.bs-datepicker-head button.current{
  padding: 0;
}

.bs-datepicker-body table td.week span 
{
  display: none;
}

.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span 
{
  color:rgba(255, 255, 255, 0.25);
}

// .bs-datepicker-head,
// .bs-datepicker-head, .bs-datepicker button:active,
// .bs-datepicker-body table td.selected span,
// .bs-datepicker-body table td span[class*="select-"]:after,
// .bs-datepicker-body table td[class*="select-"] span:after,
// .bs-datepicker-body table td.active-week span:hover 
// {
//   background-color: rgba(255, 255, 255, 0.02) !important;
// }

.bs-datepicker-head .current span {
  text-transform: capitalize;
  font-weight: 600;
}

.bs-datepicker-body .days thead tr th{
  text-transform: capitalize;
}
.bs-datepicker-container{
    padding: 0;
    font-weight: 500 !important;
}
.bs-datepicker-head button{
    color: #fff;
}
.bs-datepicker-body table.days thead{
    display: none;
}

.bs-datepicker-body table td span.selected{
  background-color: rgba(255, 255, 255, 0.02) !important;
  color: #44D62C;
}

.bs-datepicker-head button[disabled]{
    color: #fff !important;
    background-color: #181818 !important;
    
    span {
      color: gray;
    }
}

.bs-datepicker {
  box-shadow: none;
  border-top: none!important;
}

bs-datepicker-container.bottom .bs-datepicker {
  border-radius: 0 0 30px 30px;   
}

bs-datepicker-container.top .bs-datepicker,
bs-datepicker-container.top .bs-datepicker .bs-datepicker-head
{
  border-radius: 30px 30px 0 0;    
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span, 
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: transparent;
  color: #A8A8A8;
  transition: 0s;
}

bs-datepicker-container{
    margin-top: -10px;
    margin-left: 1px;
    -webkit-backdrop-filter: blur(20px) !important;
}

bs-years-calendar-view,
bs-month-calendar-view,
bs-days-calendar-view,
.bs-media-container {
  width: inherit;
}
bs-datepicker-container{
  z-index: 1056;
}

input#bsCalendar:focus {
  box-shadow: none;
}

input#bsCalendar{
  padding: 12px 14px;  
  border-radius: 10px;
  color: #fff;
}

.input-datepicker:focus {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.input-datepicker{
  padding: 12px 14px;  
  border-radius: 40px;
  color: #A8A8A8;
  background: url('../images/ripe/icon_сalendar.png') no-repeat scroll calc(100% - 14px) calc(100% - 14px);
  cursor: pointer;
  border: 1px solid rgba(168, 168, 168, 0.2);
  backdrop-filter: blur(20px);
}

.theme-form .form-control.input-datepicker {
  background-color: rgba(255, 255, 255, 0.02);
}