@charset "UTF-8";
/**=====================
     01. General CSS Start
==========================**/

.overflow-block {
  display: flex;
  padding: 14px 16px 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px 25px 0px 0px;
  background: linear-gradient(122.2deg, #3DAC37 4.75%, #FCEC00 84.07%);
}

.overflow-upsidedown {
  border-radius: 0px 0px 25px 25px !important;
}

.index-btn {
  width: 36px;
  height: 36px;
}

.index-btn img {
  width: 10px;
}

.icon-20 {
  font-size: 20px;
  width: 20px
}

.c-dark {
  color: #0D2050;
}

.c-white {
  color: #FFFFFF;
}

.c-green {
  color: #44D62C;
}

.c-black {
  color: #000000;
}

body {
  background-color: #000;
  font-size: 14px;
  position: relative;
  color: #fff;
  margin: 0;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px;
}

* a {
  color: #0D2050;
}

*.btn:focus {
  box-shadow: none !important;
}

p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

code {
  color: #0288d1 !important;
  background-color: #f6f7fb;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px;
}

:focus {
  outline-color: #0288d1;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input:focus {
  outline-color: transparent;
}

:not(pre)>code[class*=language-],
:not(pre) pre[class*=language-] {
  background: #f6f7fb;
}

.code-box-copy__btn {
  background-color: rgba(2, 136, 209, 0.2);
  border: 1px solid rgba(2, 136, 209, 0.3);
}


pre {
  background-color: rgba(2, 136, 209, 0.05);
  padding: 20px;
}

a:hover {
  text-decoration: none;
  color: #44D62C;
}

.mobile-sidebar {
  width: auto;
}

hr{
  background-color: #181818;
  height: 2px;
  opacity: 1;
  margin: 17px 0;
  border: none;
}

/**=====================
      01. General CSS Ends
==========================**/
/**=====================
     02. Generic CSS Start
==========================**/
/*====== Padding css starts ======*/
.p-0 {
  padding: 0px;
}

/*====== Padding css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px !important;
}

/*====== Margin css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  text-decoration: dashed;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  text-decoration: solid;
}

.text-wavy {
  text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  text-decoration: double;
}

/*======= Button-color css starts  ======= */
.btn-primary {
  background-color: #44D62C !important;
  border-color: transparent !important;
  color: #000 !important;
}

.btn-primary.disabled,
.btn-primary:disabled {  
  opacity: 0.7;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 35%, rgba(61,172,55,1) 100%) border-box;  
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #44D62C;
}

button.tgme_widget_login_button{
  color: white;
  background-color: transparent  !important;
  border-color: white !important;
}

.radio-button {
  border-color: transparent;
  color: var(--grey_semi_light, #A8A8A8);
  background-color: transparent;
}

.btn-check:checked+.btn, .btn-check+.btn:hover {
  border-color: #44D62C;
  background-color: #44D62C;
  color: #000000;
}

.btn-secondary {
  background-color: transparent !important;
  border-color: #FFF !important;
}

.btn-secondary.disabled {
  background-color: transparent !important;
  border-color: #646464 !important;
}

.btn-secondary:disabled {
  background-color: transparent !important;
  border-color: #646464 !important;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active {
  background-color: #252525 !important;
  border-color: #FFF !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #a9e8f0;
}

.btn-success {
  background-color: #22af47 !important;
  border-color: #22af47 !important;
}

.btn-success.disabled {
  background-color: #22af47 !important;
  border-color: #22af47 !important;
}

.btn-success:disabled {
  background-color: #22af47 !important;
  border-color: #22af47 !important;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
  background-color: #1a8436 !important;
  border-color: #1a8436 !important;
}

.btn-success:focus {
  box-shadow: 0 0 0 0.2rem #83e79d;
}

.btn-danger {
  background-color: #B42929 !important;
  color: black;
  border: none;
}

.btn-danger.disabled {
  background-color: #832222 !important;
  color: black;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
  background-color: #C35454 !important;
  color: black;
}

.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem #ffecef;
}

.btn-info {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-info.disabled {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-info:disabled {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  background-color: #0062cc !important;
  border-color: #0062cc !important;
}

.btn-info:focus {
  box-shadow: 0 0 0 0.2rem #99caff;
}

.btn-light {
  background-color: #f6f7fb !important;
  border-color: #f6f7fb !important;
  color: #2a3142 !important;
}

.btn-light.disabled {
  background-color: #f6f7fb !important;
  border-color: #f6f7fb !important;
}

.btn-light:disabled {
  background-color: #f6f7fb !important;
  border-color: #f6f7fb !important;
}

.btn-light:hover,
.btn-light:active,
.btn-light.active {
  background-color: #d3d8eb !important;
  border-color: #d3d8eb !important;
}

.btn-light:focus {
  box-shadow: 0 0 0 0.2rem white;
}

.btn-dark,
.btn-dark.disabled,
.btn-dark:disabled
{
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-dark:hover,
.btn-dark:active,
.btn-dark.active {
  background-color: #161a23db !important;
  border-color: #161a23db !important;
}

/* .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem #67779e;
} */

/*======= Button-color css ends  ======= */
.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #0288d1;
  color: #0288d1;
  background-color: transparent;
}

.btn-outline-primary-2x:hover,
.btn-outline-primary-2x:focus,
.btn-outline-primary-2x:active,
.btn-outline-primary-2x.active {
  color: white;
  background-color: #02679e !important;
  border-color: #02679e !important;
  box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #26c6da;
  color: #26c6da;
  background-color: transparent;
}

.btn-outline-secondary-2x:hover,
.btn-outline-secondary-2x:focus,
.btn-outline-secondary-2x:active,
.btn-outline-secondary-2x.active {
  color: white;
  background-color: #1e9faf !important;
  border-color: #1e9faf !important;
  box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #22af47;
  color: #22af47;
  background-color: transparent;
}

.btn-outline-success-2x:hover,
.btn-outline-success-2x:focus,
.btn-outline-success-2x:active,
.btn-outline-success-2x.active {
  color: white;
  background-color: #1a8436 !important;
  border-color: #1a8436 !important;
  box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #ff5370;
  color: #ff5370;
  background-color: transparent;
}

.btn-outline-danger-2x:hover,
.btn-outline-danger-2x:focus,
.btn-outline-danger-2x:active,
.btn-outline-danger-2x.active {
  color: white;
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
  box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #007bff;
  color: #007bff;
  background-color: transparent;
}

.btn-outline-info-2x:hover,
.btn-outline-info-2x:focus,
.btn-outline-info-2x:active,
.btn-outline-info-2x.active {
  color: white;
  background-color: #0062cc !important;
  border-color: #0062cc !important;
  box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #f6f7fb;
  color: #f6f7fb;
  background-color: transparent;
}

.btn-outline-light-2x:hover,
.btn-outline-light-2x:focus,
.btn-outline-light-2x:active,
.btn-outline-light-2x.active {
  color: white;
  background-color: #d3d8eb !important;
  border-color: #d3d8eb !important;
  box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2a3142;
  color: #2a3142;
  background-color: transparent;
}

.btn-outline-dark-2x:hover,
.btn-outline-dark-2x:focus,
.btn-outline-dark-2x:active,
.btn-outline-dark-2x.active {
  color: white;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
  box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #ff9f40;
  color: #ff9f40;
  background-color: transparent;
}

.btn-outline-warning-2x:hover,
.btn-outline-warning-2x:focus,
.btn-outline-warning-2x:active,
.btn-outline-warning-2x.active {
  color: white;
  background-color: #ff850d !important;
  border-color: #ff850d !important;
  box-shadow: none;
}

.btn-outline-primary {
  border-color: #fff;
  color: #fff;
  background-color: transparent;  
}

.btn-outline-primary.disabled {
  color: #fff;
  border-color: #FFFFFF;
  background-color: transparent; 
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-color: #FFFFFF !important;
}

.btn-outline-secondary {
  border-color: #0D2050;
    color: #0D2050;
    background-color: #FFFFFF;
    padding: 0.55rem 1rem;
}

.btn-outline-secondary.disabled {
  color: #0D2050;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active {
  color: white !important;
  background-color: #0D2050 !important;
  border-color: #0D2050 !important;
}

.btn-outline-success {
  border-color: #22af47;
  color: #22af47;
  background-color: transparent;
}

.btn-outline-success.disabled {
  color: #22af47;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active {
  color: white;
  background-color: #1a8436 !important;
  border-color: #1a8436 !important;
}

.btn-outline-danger {
  border-color: #ff5370;
  color: #ff5370;
  background-color: transparent;
}

.btn-outline-danger.disabled {
  color: #ff5370;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active {
  color: white;
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
}

.btn-outline-info {
  border-color: #FFFFFF;
  color: #FFFFFF;
  background-color: transparent;
  padding: 0.55rem 1rem;
}
.btn-outline-info.disabled {
  color: #A8A8A8; 
}

.btn-outline-info:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.08);
  border-color: white;
}
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;
  background-color: transparent !important;
}

.btn-outline-light {
  border-color: #f6f7fb;
  color: #f6f7fb;
  background-color: transparent;
  color: #2a3142;
}

.btn-outline-light.disabled {
  color: #f6f7fb;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active {
  color: white;
  background-color: #d3d8eb !important;
  border-color: #d3d8eb !important;
}

.btn-outline-dark {
  border-color: #2a3142;
  color: #2a3142;
  background-color: transparent;
}

.btn-outline-dark.disabled {
  color: #2a3142;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active {
  color: white;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-outline-warning {
  border-color: #ff9f40;
  color: #ff9f40;
  background-color: transparent;
}

.btn-outline-warning.disabled {
  color: #ff9f40;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active {
  color: white;
  background-color: #ff850d !important;
  border-color: #ff850d !important;
}

.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #44D62C;
  border-color: #44D62C;
  box-shadow: none !important;
}

.btn-primary:not([disabled]):not(.disabled).active:hover,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.btn-primary:not([disabled]):not(.disabled).active:active,
.btn-primary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #44D62C;
  border-color: #44D62C;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #1e9faf;
  border-color: #1e9faf;
  box-shadow: none !important;
}

.btn-secondary:not([disabled]):not(.disabled).active:hover,
.btn-secondary:not([disabled]):not(.disabled).active:focus,
.btn-secondary:not([disabled]):not(.disabled).active:active,
.btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #1e9faf;
  border-color: #1e9faf;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #1a8436;
  border-color: #1a8436;
  box-shadow: none !important;
}

.btn-success:not([disabled]):not(.disabled).active:hover,
.btn-success:not([disabled]):not(.disabled).active:focus,
.btn-success:not([disabled]):not(.disabled).active:active,
.btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #1a8436;
  border-color: #1a8436;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #ff2046;
  border-color: #ff2046;
  box-shadow: none !important;
}

.btn-danger:not([disabled]):not(.disabled).active:hover,
.btn-danger:not([disabled]):not(.disabled).active:focus,
.btn-danger:not([disabled]):not(.disabled).active:active,
.btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #ff2046;
  border-color: #ff2046;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #0062cc;
  border-color: #0062cc;
  box-shadow: none !important;
}

.btn-info:not([disabled]):not(.disabled).active:hover,
.btn-info:not([disabled]):not(.disabled).active:focus,
.btn-info:not([disabled]):not(.disabled).active:active,
.btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0062cc;
  border-color: #0062cc;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #d3d8eb;
  border-color: #d3d8eb;
  box-shadow: none !important;
}

.btn-light:not([disabled]):not(.disabled).active:hover,
.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled).active:active,
.btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #d3d8eb;
  border-color: #d3d8eb;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #161a23;
  border-color: #161a23;
  box-shadow: none !important;
}

.btn-dark:not([disabled]):not(.disabled).active:hover,
.btn-dark:not([disabled]):not(.disabled).active:focus,
.btn-dark:not([disabled]):not(.disabled).active:active,
.btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #161a23;
  border-color: #161a23;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #0288d1;
  border-color: #0288d1;
  box-shadow: none !important;
  color: white;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #02679e !important;
  border-color: #02679e !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #26c6da;
  border-color: #26c6da;
  box-shadow: none !important;
  color: white;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #1e9faf !important;
  border-color: #1e9faf !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #22af47;
  border-color: #22af47;
  box-shadow: none !important;
  color: white;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #1a8436 !important;
  border-color: #1a8436 !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #ff5370;
  border-color: #ff5370;
  box-shadow: none !important;
  color: white;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none !important;
  color: white;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #0062cc !important;
  border-color: #0062cc !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #f6f7fb;
  border-color: #f6f7fb;
  box-shadow: none !important;
  color: white;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #d3d8eb !important;
  border-color: #d3d8eb !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2a3142;
  border-color: #2a3142;
  box-shadow: none !important;
  color: white;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #ff9f40;
  border-color: #ff9f40;
  box-shadow: none !important;
  color: white;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #ff850d !important;
  border-color: #ff850d !important;
}

/**====== custom scrollbar css start ======**/
.digits {
  /* font-family: work-Sans, sans-serif; */
}

.custom-scrollbar {
  transition: all 3s;
  overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 14px;
  transition: .3s;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #44D62C !important;
  height: 40px;
  border-radius: 6px;
  border: 4px solid transparent;
  background-clip: padding-box; 
}

.col-in-scrollbar{
  padding: 0px 10px;
}

.col-in-scrollbar:first-child{
  padding: 0 10px 0 0;
}

.col-in-scrollbar:last-child{
  padding: 0 0 0 10px;
}


/**====== Custom scrollbar css end ======**/

/**=====================
    03.  Card CSS Start
==========================**/
.card {
  margin-bottom: 20px;
  padding: 20px;
  /* transition: all 0.3s ease; */
  letter-spacing: 0.5px;
  border-radius: 25px;
  position: relative;
  border: 0px solid transparent;
  background-clip: padding-box;
  background: linear-gradient(135.55deg, rgba(62, 192, 41, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #121212;
}

.card-black{
  background: var(--grey_semi_dark, #181818);
}

/* For gradient border display*/
.card::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(138.79deg, rgba(255, 255, 255, 0.35) 0%, rgba(68, 214, 44, 0.35) 10%, rgba(68, 214, 44, 0.035) 50%, rgba(68, 214, 44, 0.35) 90%, rgba(255, 255, 255, 0.35) 100%);
  content: '';
  z-index: -1;
  border-radius: 25px;
}

.card-carousel.card::after {
  background: none;
}

.card hr{
  background: #A8A8A80D
}

.song-card {
  /* transition: all 0.3s ease; */
  letter-spacing: 0.5px;
  border-radius: 10px;
  width: 240px;
  height: 236px;
  padding: 15px;
  background-size: cover;
}

.song-card-header {
  font-size: 20px;
  text-transform: uppercase;
}

.card-header,
.card-header h5{
  background-color: transparent !important;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  /* font-family: Museo Sans Cyrl,  work-Sans, sans-serif; */
}

.card .card-header {
  border-bottom: none;
  padding: 0;
  border-radius: inherit;
  line-height: 120%;
  margin-bottom: 20px;
}

.card .card-header h5 {
  margin-bottom: 0;
}

.card .card-header .user-name {
  font-size: 26px;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: bold;
}

.card .card-header .user-email {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: initial;
}

.card .card-header .custom-filter{
  text-transform: none;
}

.filter-gray {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
}

.filter-white {
  filter: white; /* IE6-9 */
  filter: grayscale(1) brightness(200%);
  -webkit-filter: grayscale(1) brightness(200%); /* Google Chrome, Safari 6+ & Opera 15+ */
}

/* Работает только для Desktop */
@media (min-width: 992px) {
  .filter-white:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
  }
}

.border-bottom{
  border-bottom: 1px solid #C5C5C5;
}

.border-green {
  border-color: #44D62C !important;
}

.card .c-black{
  color: #000;
}

.card .c-white{
  color: #FFFFFF;
}

.card .min-token-width{
  min-width: 80px;
}

.card .icon-width{
  width: 20px;
}

.row .t-center{
  text-align:center;
}

.t-right{
  text-align:right;
}

.card .card-header>span {
  font-size: 12px;
  color: #777777;
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
}

.card .card-body {
  padding: 0;
  background-color: transparent;
}

.card .card-body p:last-child {
  margin-bottom: 0;
}

.sub-font-55 {
  font-size: 55px;
  line-height: 56px;
  font-weight: 682;
}

.sub-font-40 {
  font-size: 40px;
  line-height: 56px;
  font-weight: 682;
}

.sub-font-32,
.card .sub-font-32 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

.sub-font-16,
.card .sub-font-16 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 682;
}

.sub-font-22 {
  font-size: 22px;
  line-height: 37px;
  font-weight: 600;
}

.sub-font-15,
.card .sub-font-15 {
  font-size: 15px;
  /* line-height: 19px; */
  font-weight: 400;
}

.sub-font-13 {
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
}

.sub-font-14 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
}

.card .card-footer {
  background-color: #fff;
  border-top: 1px solid #f6f7fb;
  padding: 30px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 20px;
  top: 23px;
  display: inline-block;
  float: right;
  padding: 7px 0;
  position: absolute;
  background-color: #fff;
}

.card .card-header .card-header-right i {
  margin: 0 5px;
  cursor: pointer;
  color: #2a3142;
  line-height: 20px;
}

.card .card-header .card-header-right i.icofont-refresh {
  font-size: 13px;
}

.card .server-header .card-header-right {
  top: 24px;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  align-items: center;
  justify-content: center;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: #0288d1;
  font-size: 20px;
}

.card.full-card {
  position: fixed;
  top: 80px;
  z-index: 99999;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #eff0f1;
  width: calc(100vw);
  height: calc(100vh);
}

.card.full-card .card-body {
  overflow: auto;
}

.card-absolute {
  margin-top: 20px;
}

.card-absolute .card-header {
  position: absolute;
  top: -20px;
  margin-bottom: 30px;
  left: 15px;
  border-radius: 0.25rem;
  padding: 10px 15px;
}

.card-absolute .card-header h5 {
  font-size: 17px;
}

.card-absolute .card-body {
  margin-top: 10px;
}

.card-header .nav-material {
  margin-bottom: -13px;
}

.card .card-img{
  height: 250px;
  border-radius: inherit;
}

.card .card-carousel{
  cursor: grab;
  margin: 0;
  transition: 0s;
}

.card .card-carousel-dasboard{
  height: 394px;
}

.modal-content .card::after {
  display: none;
}

.modal-content .card {
  background: #121212;
  border: none;
}

/**=====================
    03. Card CSS End
==========================**/

.footer-copyright {
  margin-bottom: -5px;
  color: #C5C5C5;
}

/**=====================
    05. Header CSS Start
==========================**/


.onhover-show-div {
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
  position: absolute;
  z-index: 8;
  transition: all linear 0.3s;
  border-radius: 15px;
  border: none;
  background-color: rgba(18, 18, 18);
  backdrop-filter: blur(10px);
  padding: 20px;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}

.cursor-pointer {
  cursor: pointer;
}

.onhover-show-div li a svg {
  margin-top: 0 !important;
}

.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #313131 !important;
}

.page-wrapper{
  padding: 20px;
}

.page-wrapper .page-main-header.unvisible .main-header-right .nav-right ul li:first-child:before,
.page-wrapper .page-main-header.unvisible .main-header-right .nav-right ul li:before,
.page-wrapper .page-main-header.unvisible .main-header-right .nav-right ul li:last-child:before {
  position: absolute;
  content: '';
  background-color: #ebebeb;
  left: 0;
  margin: 0 auto;
  right: 0;
  z-index: 2;
  animation: shine-lines 2.0s infinite ease-out;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(246, 246, 246, 0.6) 0px, #ebebeb 40px, rgba(246, 246, 246, 0.6) 80px);
}

/*======= Page Header css Start ======= */
.page-wrapper .page-main-header {
  width: 100%;
  display: flex;
  align-items: center;
  /* transition: 0.3s; */
  margin-bottom: 20px;
}

@media (max-width: 569px) {
  .page-wrapper .page-main-header {
    position: fixed;
    z-index: 2;
    padding-right: 30px;
    background-color: #121212;
    margin: -20px;
    padding: 5px 10px;
  }
  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 60px;
  }

  .footer {
    padding: 15px 10px;
  }
}

.page-wrapper .page-main-header.open {
  margin-left: 0;
  width: 100%;
  transition: 0.3s;
}

.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 118px); 
  /* margin-top: 105px; */
  position: relative;
  /* padding: 0px 30px 0px 30px; */
  /* transition: 1s cubic-bezier(0.4, 0, 0.2, 1); */
  /* -webkit-transition: 1s cubic-bezier(0.4, 0, 0.2, 1); */
}

.page-wrapper .page-body-wrapper .page-header {
  padding-top: 30px;
  padding-bottom: 30px;
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  margin-left: 30px;
}

/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.page-main-header {
  max-width: 100vw;
}

.page-main-header .main-header-right .nav-right {
  text-align: right;
  padding-left: 0px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.page-main-header .main-header-right .nav-right .icon-user {
  font-size: 16px;
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.page-main-header .main-header-right .nav-right>ul {
  display: flex;
  align-items: center;
}

.page-main-header .main-header-right .nav-right>ul>li a {
  text-decoration: none;
}

.page-main-header .main-header-right .nav-right>ul>li:last-child {
  border-left: none;
  padding-right: 0;
}

.page-main-header .main-header-right .nav-right>ul>li:nth-child(5) {
  border: none;
  padding-left: 0;
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul:before,
.page-main-header .main-header-right .nav-right>ul>li h6 ul:after {
  left: inherit;
  right: 10px;
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul li {
  display: block;
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul li a {
  font-size: 14px;
  color: #2a3142;
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2a3142;
}

.page-main-header .main-header-right .nav-right>ul .flag-icon {
  font-size: 16px;
}

.page-main-header .main-header-right .nav-right .language-dropdown {
  width: 160px;
  text-align: left;
  top: 57px;
}

.page-main-header .main-header-right .nav-right .language-dropdown li {
  padding-top: 10px;
}

.page-main-header .main-header-right .nav-right .language-dropdown li a {
  color: #2a3142;
  width: max-content;
  text-decoration: none;
}

.page-main-header .main-header-right .nav-right .language-dropdown li a i {
  margin-right: 10px;
}

.page-main-header .main-header-right .nav-right .language-dropdown li:first-child {
  padding-top: 0;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
  right: -10px;
  left: inherit;
  width: 150px;
  top: 63px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li {
  display: block;
  text-align: left;
  padding-top: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(3) {
  padding-bottom: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(4) {
  border-top: 1px solid #eff0f1;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:first-child {
  padding-top: 0;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
  color: #0288d1;
  transition: 0.3s;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg {
  color: #0288d1 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line {
  color: #0288d1 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a {
  color: #313131;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
  margin-right: 10px;
  color: #313131 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a svg path,
.page-main-header .main-header-right .nav-right .profile-dropdown li a svg line {
  color: #313131 !important;
}

.offcanvas .page-wrapper .page-body-wrapper .page-body {
  position: relative;
}

.offcanvas .page-wrapper .page-body-wrapper .page-body:before {
  position: fixed;
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 8;
  right: 0;
  left: 0;
}

.offcanvas-bookmark.page-wrapper .page-body-wrapper .page-body .bookmark ul li a {
  z-index: 99999;
  color: rgba(255, 255, 255, 0.5) !important;
}

.offcanvas-bookmark.page-wrapper .page-body-wrapper .page-body .bookmark ul li form .form-control-search .Typeahead-menu .ProfileCard .ProfileCard-details .ProfileCard-realName a {
  color: #313131 !important;
}

.offcanvas-bookmark:before {
  position: fixed;
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 99;
  right: 0;
  left: 0;
  top: 0;
}

.page-main-header .main-header-right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* padding: 0 40px; */
  margin: 0;
}

.page-main-header .main-header-right svg line {
  color: #0288d1;
}

.page-main-header .main-header-right .nav-left i {
  margin-right: 20px;
}

.page-main-header .main-header-right .nav-left input:focus {
  outline: 0 !important;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation {
  position: relative;
  right: -3px;
  top: -7px;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid #0288d1;
  border-radius: 70px;
  animation: heartbit 1s ease-out;
  animation-iteration-count: infinite;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  background-color: #0288d1;
  position: absolute;
  right: 6px;
  top: -10px;
}

.page-main-header .main-header-right .nav-right ul li svg {
  cursor: pointer;
  margin-top: 10px;
  width: 18px;
  height: 18px;
  color: #0288d1;
}

.page-main-header .main-header-right .nav-right ul li .dot {
  width: 3px;
  height: 3px;
  border-radius: 30px;
  background-color: #0288d1;
  position: absolute;
  right: 17px;
  bottom: 6px;
  animation: blink 1.5s infinite;
}

.page-main-header .main-header-right .nav-right ul li .dot-chat {
  right: 4px;
  bottom: -11px;
}

.page-main-header .main-header-right .nav-right .notification {
  position: absolute;
  top: 21px;
  right: -1px;
  font-size: 9px;
  animation: blink 1.5s infinite;
}

.page-main-header .main-header-right .nav-right>ul>li {
  position: relative;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group {
  width: 100%;
  position: relative;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group .Typeahead-menu .ProfileCard .ProfileCard-avatar .header-search svg {
  margin-top: 0;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group .Typeahead-menu .ProfileCard .ProfileCard-details .ProfileCard-realName a {
  color: #313131;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:focus {
  outline-color: transparent;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group input:focus {
  outline-color: transparent;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 51px;
  top: 9px;
  z-index: 2;
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:after {
  position: absolute;
  content: "\f002";
  /* font-family: FontAwesome; */
  top: 11px;
  left: 22px;
  color: #8e8e8e;
}

.page-main-header .main-header-right .nav-right .notification-dropdown {
  top: 35px;
}

ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: 10px;
  left: initial;
}

ul.notification-dropdown.onhover-show-div li {
  display: block;
  text-align: left;
}

ul.notification-dropdown.onhover-show-div li h6 small {
  padding-top: 3px;
  color: #898989;
  font-size: 12px;
}

ul.notification-dropdown.onhover-show-div li span svg {
  margin-top: 0 !important;
  margin-right: 10px;
  vertical-align: text-top;
}

ul.notification-dropdown.onhover-show-div li span .shopping-color path,
ul.notification-dropdown.onhover-show-div li span .shopping-color line {
  color: #0288d1;
}

ul.notification-dropdown.onhover-show-div li span .download-color path,
ul.notification-dropdown.onhover-show-div li span .download-color line {
  color: #22af47;
}

ul.notification-dropdown.onhover-show-div li span .alert-color path,
ul.notification-dropdown.onhover-show-div li span .alert-color line {
  color: #ff5370;
}

/**=====================
    05. Header CSS Ends
==========================**/
/**=====================
    80. Sidebar CSS Start
==========================**/

.page-wrapper .page-body-wrapper .sidebar {
  height: 100%;
  overflow: hidden;
  /* box-shadow: 0 0 11px rgba(69, 110, 243, 0.13); */
  padding: 20px;
  transition: 0s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: 0s cubic-bezier(0.4, 0, 0.2, 1);
}

.page-wrapper .page-body-wrapper.openSidebar .sidebar{
  width: 190px;
}

.page-wrapper .page-body-wrapper.colapsedSidebar .sidebar{
  width: 64px;
}

.openSidebar .title-hidden .menu-title-container,
.openSidebar .sidebar-footer{
  opacity: 1;
  transition: 1s;
}

.colapsedSidebar .title-hidden .menu-title-container,
.colapsedSidebar .sidebar-footer{
  opacity: 0 !important;
  transition: 0.5s;
}

.green-transition{
  transition: 0s !important;
}

.page-wrapper .page-body-wrapper .sidebar.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}

.page-wrapper .page-body-wrapper .sidebar.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  transition: .3s;
}

.page-wrapper .page-body-wrapper .sidebar.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.page-wrapper .page-body-wrapper .sidebar:hover.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.page-wrapper .page-body-wrapper .page-sidebar {
  max-width: 190px;
  /* position: fixed; */
  background: transparent;
  transition: 0.5s;
  /* padding: 20px; */
  border-radius: 15px;
  margin-right: 20px;
}


@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 0px;
  }
}





.page-wrapper .page-body-wrapper .page-sidebar.open {
  display: block;
  margin-left: calc(-260px);
}

.page-wrapper .page-body-wrapper .page-sidebar.open~.page-body {
  margin-left: 0;
  /* transition: 0.3s; */
}

.page-wrapper .page-body-wrapper .page-sidebar.open~footer {
  margin-left: 0;
  padding-right: 15px;
}

.page-wrapper .page-body-wrapper .sidebar-close .page-sidebar {
  transition: 0.3s;
  transform: translate(-255px);
}

.page-wrapper .page-body-wrapper .sidebar-close .page-body {
  /* transition: 0.3s; */
  margin-left: 0 !important;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar {
  transform: translate(-180px);
  transition: 0.3s;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar:hover {
  transform: translate(0px);
}

@media (max-width: 568px) {
  .desktop-show{
    display: none !important;
  }

  .page-wrapper .page-body-wrapper .page-sidebar {
    max-width: unset;
    width: 65vw;
    position: fixed;
    margin: 0;
    border-radius: 0;
    background: var(--grey_dark, #121212);
    z-index: 4;
    transition: all 0.5s !important;
    height: 100vh !important;
    /* height: calc(100vh - calc(100vh - 100%)) !important; */
    padding: 20px;
    padding-top: 29px;
    top: 0;
    right: 0;
  }
  
  .page-wrapper .page-body-wrapper.colapsedSidebar .page-sidebar{
    display: block;
    margin-right: calc(-65vw);
  }

  .page-wrapper .page-body-wrapper.openSidebar .page-sidebar{
    margin-right: 0;
  }

  .page-wrapper .page-body-wrapper.colapsedSidebar .sidebar,
  .page-wrapper .page-body-wrapper.openSidebar .sidebar {
    padding: 0;
    width: 100%;
  }
  
  .black-overlay{
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
  }

  .colapsedSidebar .black-overlay{
    opacity: 0;
    display: none;
    transition: 0.3s;
  }

  .title-hidden{
    font-size: 16px !important;
  }
  
  .icon-color{
    width: 35px !important;
    height: 35px !important;
  }
}

@media (min-width: 569px) {
  .mobile-show{
    display: none !important;
  }
}

/**=====================
    80. Sidebar CSS Ends
==========================**/

/**=====================
     47. Footer CSS Start
==========================**/
.footer-links {
  text-align: right;
}

.footer-links li {
  display: inline-block;
  padding-right: 15px;
}

.footer-links li a {
  font-size: 12px;
}

footer {
  background-color: transparent;
  padding: 15px 25px;
  bottom: 0;
  left: 0;
}

footer a {
  font-weight: 600;
}

footer.footer-starter-kit {
  bottom: -52px;
}

.footer-dark {
  background-color: #2f3c4e;
}

.footer-dark p {
  color: #fff;
}

.footer-dark li a {
  color: #fff;
}

.footer-fix {
  position: fixed;
  width: calc(100% - 255px);
}

.page-wrapper .page-body-wrapper footer {
  transition: all 0.3s ease;
  bottom: 0;
  z-index: 9;
}

.page-wrapper .page-body-wrapper footer p {
  color: #a8a8a8;

}

.page-wrapper .page-body-wrapper footer p i {
  color: #d75a4a;
  margin-left: 5px;
}

/*.sidebar-footer{
  color: #181818;
  font-family: Museo Sans Cyrl;
  font-size: 14px;
  font-weight: 400;
  min-height: 740px;
}*/

/**=====================
     47. Footer CSS Ends
==========================**/
/**=====================
    06. Button CSS start
==========================**/

.btn {
  font-size: 15px;
  font-weight: 800;
  border-radius: 57px;
  /* font-family: Museo Sans Cyrl, work-Sans, sans-serif; */
  padding: 8px 15px;
  font-style: normal;
}

.btn-lg {
  font-size: 18px;
}

.btn-block {
  width: 100%;
}

/**=====================
     06. Button CSS end
==========================**/
/**=====================
     16. Popover CSS Start
==========================**/
.popover-header {
  background-color: #2a3142;
  color: #fff;
}

/**=====================
     16. Popover CSS Ends
==========================**/
/**=====================
     17. List CSS Start
==========================**/

.list-group-item.active {
  background-color: #0288d1;
  border-color: #0288d1;
  z-index: 1;
}

.list-group i {
  margin-right: 10px;
}

.list-group img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

/**=====================
     17. List CSS Ends
==========================**/
/**=====================
    25. Authentication CSS Start
==========================**/

.auth-bg {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  padding: 20px 35px;
}

@media (max-width: 569px) {
  @supports (min-height: 100dvh) {
    .auth-bg {
      min-height: 100dvh;
    }
  }
}

.auth-bg-admin{
  background-image: url('../images/background/lk_1.png');
}

.auth-col-width{
  flex: 0 0 auto;
  width: 100%;
}

 @media (max-width: 768px) {
  .auth-bg {
    background-size: cover;
    background-image: url('../images/background/mobile_sign_bg.png');
    background-position: center;
  }  
}
  
.align-center{
  align-items: center;
  text-align: center !important;
} 

@media (min-width: 901px) {
  .auth-bg{
    background-size: 61%;
    background-position-x: 164%;
    background-position-y : center;
    background-repeat: no-repeat;
  }

  .auth-col-width{
    width: 50%;
  }
}

/* @media (min-width: 1300px){
  .auth-bg{
    background-size: 50% auto !important;
  }
} */

.col-auth-bg{
  background-image: url('/assets/images/background/login_disk.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} 

@media only screen and (max-width: 575px) {
  .auth-bg {
    padding: 25px 15px;
  }
}

.auth-bg .auth-block {
  max-width: 360px;
}

.auth-bg .auth-bg-title {
  font-size: 36px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 43.2px;
}

.auth-bg .card-body .login-header {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 16px;
}

.authentication-box {
  width: 360px;
  margin: auto; 
}

@media (min-width: 901px) and (max-width: 1050px){
  .authentication-box {
    width: 315px;
    margin: 0; 
  }
}

@media (max-width: 400px){
  .authentication-box {
    width: 300px;
  }
  .d-none-400{
    display: none;
  }

  .authentication-box:has(app-not-found) {
    width: 360px !important; 
}
}

@keyframes rotate-effect {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**=====================
    25. Authentication CSS Ends
==========================**/

.avatar-circle-36 {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  object-fit: cover;
}


.circle-22 {
  background:#09f;
  clip-path: circle(50%);
  height: 22px;
  width: 22px;
}

.avatar-circle-60 {
  clip-path: circle(50%);
  height: 60px;
  width: 60px;
}

.avatar-circle-70 {
  clip-path: circle(50%);
  height: 70px;
  width: 70px;
}

.image-circle-150 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.image-circle-190 {
  border-radius: 50%;
  width: 190px;
  height: 190px;
  object-fit: cover;
}

.theme-form .form-group {
  margin-bottom: 20px;
}

.theme-form .form-group textarea {
  border-color: #eff0f1;
}

.theme-form .form-group textarea::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.theme-form .form-group input[type=text],
.theme-form .form-group input[type=email],
.theme-form .form-group input[type=search],
.theme-form .form-group input[type=password],
.theme-form .form-group input[type=number],
.theme-form .form-group input[type=tel],
.theme-form .form-group input[type=date],
.theme-form .form-group input[type=datetime-local],
.theme-form .form-group input[type=time],
.theme-form .form-group input[type=datetime-local],
.theme-form .form-group input[type=month],
.theme-form .form-group input[type=week],
.theme-form .form-group input[type=url],
.theme-form .form-group input[type=file],
.theme-form .form-group textarea[type=text],
.theme-form .form-group input[type=checkbox],
.theme-form .form-group input,
.theme-form .form-group ng-select,
.theme-form .form-group select,
.datatable-body-cell-label input,
.theme-form .search-input-container {
  font-size: 15px;
  /* font-family: Museo Sans Cyrl, work-Sans, sans-serif; */
  padding: 8px 15px;
  appearance: none;
  color: #A8A8A8;
  background: rgba(255, 255, 255, 0.02) !important;
  border: 1px solid rgba(168, 168, 168, 0.2);
  backdrop-filter: blur(6px);
  border-radius: 40px;
}

.theme-form .form-group ng-select {
  padding: 0 !important;
}

.theme-form .form-group .ng-select-container {
  padding: 8px 15px !important;
}

.theme-form .form-check input[type="radio"].form-check-input {
  background-color: #292929;
}

.theme-form .form-check input[type="radio"].form-check-input:checked {
  background-image: url('../images/ripe/icons/radio_active.svg');
  background-color: #292929;
  border: none;
}

.theme-form .form-check input[type="radio"].form-check-input:focus {
  box-shadow: none;
  border-color: #44D62C;
}

.theme-form .form-check input[type="checkbox"].form-check-input {
  background-color: var(--grey-semi-dark) !important;
  border-color: #44D62C !important;
  border: 1px solid #44D62C !important;
}

.theme-form .form-check input[type="checkbox"].form-check-input:focus {
  box-shadow: none;
}

.theme-form .form-check input[type="checkbox"].form-check-input:checked {
  background-color: #292929 !important;
  border: none !important;
}

.theme-form .form-check-label {
  font-size: 16px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield !important;
}

.confirm-code-input{
  font-size: 16px !important;
  font-weight: 400;
  max-width: 50px;
  height: 50px;
  margin: auto;
  padding: 10px 0px;
  color: #333333;
  align-self: stretch;
  text-align: center;
  z-index: 3 !important;
}

.theme-form .form-group .form-control-heigth{
  /* font-family: work-Sans, sans-serif; */
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 15px;
}

.pull-right{
  float: right;
}

.search-input input[type=text],
input.search-input{
  background-color: transparent;
  color: white;
  border: none;
  /* font-family: Museo Sans Cyrl, work-Sans, sans-serif; */
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.search-input input[type=text]::-webkit-input-placeholder{
  color: white;
  /* font-family: Museo Sans Cyrl, work-Sans, sans-serif; */
  font-size: 14px;
  font-weight: 400;
}

.search-input input[type=text]:active,
.search-input input[type=text]:focus{
  outline: none;
}

.search-input:focus{
  border: none;
}

.theme-form .form-group input[type=text]::-webkit-input-placeholder,
.theme-form .form-group input[type=email]::-webkit-input-placeholder,
.theme-form .form-group input[type=search]::-webkit-input-placeholder,
.theme-form .form-group input[type=password]::-webkit-input-placeholder,
.theme-form .form-group input[type=number]::-webkit-input-placeholder,
.theme-form .form-group input[type=tel]::-webkit-input-placeholder,
.theme-form .form-group input[type=date]::-webkit-input-placeholder,
.theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder,
.theme-form .form-group input[type=time]::-webkit-input-placeholder,
.theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder,
.theme-form .form-group input[type=month]::-webkit-input-placeholder,
.theme-form .form-group input[type=week]::-webkit-input-placeholder,
.theme-form .form-group input[type=url]::-webkit-input-placeholder,
.theme-form .form-group input[type=file]::-webkit-input-placeholder,
.theme-form .form-group textarea[type=text]::-webkit-input-placeholder,
.theme-form .form-group input[type=checkbox]::-webkit-input-placeholder,
.theme-form .form-group ng-select::-webkit-input-placeholder,
.theme-form .form-group select::-webkit-input-placeholder,
.datatable-body-cell-label input::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.col.input-container{
  /* padding: initial; */
  padding-left: clamp(5px, 7px, 15px);
  padding-right: clamp(5px, 7px, 15px);
}

input[type=checkbox]{
  border-radius: 20% !important;
  width: 17px;
  height: 17px;
  padding: 0 !important;
  border: 1px solid green !important;
}

input[type=checkbox]:checked{
  text-align: center;
  line-height: 1.2;
  background-color: #44D62C !important;
}

input[type=checkbox]:checked:before {
  content: url('../images/checkbox_active.svg');
  vertical-align: text-top;
  line-height: 11px;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url('../images/ripe/switch_active.svg') !important;
  background-color: rgba(68, 214, 44, 0.15) !important;
  border: 1px solid #44D62C !important;
}

.form-switch .form-check-input:focus{
  background-image: url('../images/ripe/switch_inactive.svg');
}

.form-switch .form-check-input {
  width: 35px;
  height: 20px;
  box-shadow: none;
  margin-left: -2.5em;
  border-radius: 20px !important;
  background-color: transparent !important;
  background-image: url('../images/ripe/switch_inactive.svg');
  border: 1px solid rgba(168, 168, 168, 1) !important;
}

.form-switch .form-check-input:before {
  content: none !important;
}

input.search-icon{
  background-image: url('../images/ripe/search.svg');
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 15px;
}

.theme-form .form-group select.form-control:not([size]):not([multiple]) {
  border-color: #eff0f1;
  font-size: 14px;
}

.theme-form input[type=file] {
  height: auto;
}

.theme-form .form-control {
  border-color: rgb(192 192 192 / 15%);
  color: #A8A8A8;    
  border-radius: 40px;
  background-color: var(--grey_semi_dark, #181818);
}


.theme-form .form-control.input-datepicker.top.iconShow {
  border-radius: 0 0 22px 22px;  
}

.theme-form .form-control.input-datepicker.iconShow {
  border-radius: 22px 22px 0 0;  
  border-bottom: 1px solid transparent;
}

.theme-form .form-control:focus,
.theme-form .form-control .input-extended:focus {
  color: #fff !important;
  border-color:  rgb(192 192 192 / 15%) !important;  
  box-shadow: 0 0 0 1000px transparent inset;
}

.theme-form.sm-form .form-group {
  margin-bottom: 14px;
}

.theme-form .form-group {
  margin-bottom: 15px;
}

.theme-form .form-group .form-label {
  font-size: 16px;
}

.theme-form .form-group textarea {
  border-color: #eff0f1;
}

.theme-form .form-group textarea::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}
.theme-form .form-group select.form-control:not([size]):not([multiple]) {
  border-color: #eff0f1;
  font-size: 14px;
}

.theme-form .checkbox label {
  padding-left: 10px;
}

.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #eff0f1;
}

.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "Or Signup With";
  color: #1b252a;
  background: #fff;
}

.theme-form .login-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 10px 0;
  border-top: 1px solid #eff0f1;
}

.theme-form .login-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #A8A8A8;
}

.login-divider-text {
  color: #A8A8A8;
  margin-top: auto;
  margin-bottom: auto;
}

.theme-form .social .form-group {
  justify-content: center;
}

.theme-form .social .form-group .btn {
  padding: 6px 15px;
}

.theme-form .social .form-group .btn:last-child {
  margin-right: 0;
}

.theme-form .form-group .login-sr-only {
  margin-bottom: 5px;
  font-weight: 500;
}

.person-inviter {
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgba(0, 174, 239, 0.30000001192092896);
  margin: 10px 0 0 15px;
}

.profile-fonts  {
  font-size: 15px;
  /* line-height: 24px; */
  font-weight: 400;
}

/**======================
    Section Header
========================**/
.section-header {
  background-color: transparent;
  border-bottom: none;
  padding: 14px 0 34px 0;
}

.section-header span {
  font-size: 44px;
  line-height: 44px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.section-header>button {
  vertical-align: middle;
  float: right;
}

.icon-color {
  filter: grayscale(1); 
  width: 22px; 
  height: 22px;
}

.title-hidden {
  color: white;
  /* font-family: Museo Sans Cyrl, work-Sans, sans-serif; */
  font-weight: 400;
  font-size: 14px;
  transition: 0.5s;
}
.menu-item {
  height: 50px;
  color: #000;
}

.menu-item.active-icon,
.menu-item:hover {
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
}

.active-image {
  filter: grayscale(0);
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
}

.active-icon .active-image img.icon-color,
.menu-item:hover img.icon-color,
.active-icon .active-image .filter-white,
.menu-item:hover .filter-white {
  filter: grayscale(0); 
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
}

.active-icon .active-image,
.menu-item:hover div.title-hidden .green-transition {
  color: #44D62C;
}

.modal-dialog .modal-content{
  background-color: transparent;
  border: none;
}

.w-auto .modal-content{
  width: auto;
}

.modal-dialog-centered {
  justify-content: center;
}

.img-22 {
    width: 22px;
    height: 22px;
}
.input-label{
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}
.close-modal-btn{
  margin-right: -40px;
}

.custom-banner-modal{
  /*чтоб модалка отображалась как в лк*/
  --bs-modal-width: fit-content;
}

.card-modal-bg-color
{
  background-color: #121212 !important;
}

.card.card-modal-bg-color
{
  background: none;
  border-color: #121212;
}

/* чтобы картинка была в инпуте */
.input-with-image {
  position: relative;
}

.input-with-image label {
  position: absolute;
  top: 50%;
  right: 27px;
  transform: translateY(-50%);
  cursor: pointer;
}

.product-box{
  border-radius: inherit;
}

.product-box .product-img {
  border-radius: inherit;
  height: 400px;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.product-box .product-img .product-hover {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 174, 239, 0.5);
  border-radius: inherit;
  opacity: 0;
}

.product-box .product-img .product-hover ul li:hover .btn,
.product-box .product-img .product-hover ul li:hover i {
  background-color: #0D2050;
  color: white;
  cursor: pointer;
}

.product-box:hover .product-hover {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-out;
}

.product-price{
  color: #00AEEF; 
  cursor: pointer; 
  font-size: 56px; 
  font-weight: 700;
}
  .row>*{
  padding-left: 15px;
  padding-right: 15px;
}

.custom-modal .modal-dialog{
  max-width: max-content;
}

.custom-modal .modal-content{
  background-color: #fff0;
  border: 1px solid rgba(0, 0, 0, 0);
}

@media (max-width: 750px){
  .times-mobile{
    display: none;
  }
}

.pointer{
  cursor: pointer;
}

.pl-0{
  padding-left: 0px;
}

.pr-0{
  padding-right: 0px;
}

.pt-0{
  padding-top: 0px;
}

.pb-0{
  padding-bottom: 0px;
}

.min-w-200{
  min-width: 200px;
}

.max-w-250{
  max-width: 250px;
}

.max-w-500{
  max-width: 500px;
}

.max-w-450{
  max-width: 450px;
}

.max-w-400{
  max-width: 400px;
}

.min-w-250{
  min-width: 250px;
}
.min-w-400{
  min-width: 400px;
}

.max-h-400{
  max-height: 400px;
}

.max-h-550{
  max-height: 550px;
}

.max-h-39{
  max-height: 39px;
}

.col-5ths{
  width: 20%;
}

.news-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.news-img:hover .overlay-blue {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-out;
}

.overlay-blue{
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(0, 174, 239, 0.5);
  border-radius: inherit;
  opacity: 0;
}

.iframe-main-class-for-videos {
  width: -moz-available;
  width: -webkit-fill-available;
  height: auto;
}

.max-w-303{
  max-width: 303px;
}
input[type=checkbox].input-danger {
  border: #B42929;
}

input.ng-dirty.ng-invalid, textarea.ng-dirty.ng-invalid {
  border: #B42929;
}

.ng-invalid:focus{
  box-shadow: none;
}

.order-modal .modal-dialog{
  width: 596px;
}

.document-modal .modal-dialog{
  width: 335px;
}

.theme-form .form-group textarea.textarea-110{
  min-height: 110px;
}

.inviters {
  padding: 8px 15px;
  background-color: rgba(0, 174, 239, 0.4);
  border-radius: 5px;
  font-size: 13px;
  line-height: 19px;
  color: #0D2050;
  opacity: 0.8;
}

.doc-preview {
  width: 100px;
  height: 100px;
  background-color: #E5E5E5;
  border-radius: 10px;
}

.popup-custom-class{
  border: none;
  box-shadow: 0px 6px 30px rgba(51, 58, 85, 0.1);
}
.popup-custom-class .popover-body{
  color: inherit !important;
}
.popup-custom-class .popover-arrow{
  display: none;
}

.grid-area-1{
  grid-area: 1/-1;
}

/* пока так как в макете появится, переделаю */
.img-container::-webkit-scrollbar {
    height: 5px;
  }

  .img-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 2.5px;
    cursor: pointer;
  }

  .modal-img-uploaded{
    height: 100px;
    width: 100px;
  }

  .reFillChartByHover g path.bar:hover{
    fill: #007bff;
  }

.line-height-250 {
  line-height: 250px;
}

.shadedButton{
  border-radius: 20%;
  background: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20));
}

.doc-card{
  background-image: url('/assets/icons/public/document-blue.svg');
  background-position: top 20px left 20px;
  background-repeat: no-repeat;
  border: 1px solid #00AEEF;
  border-radius: 10px;
  height: 225px;
}

.text-nonecase{
  text-transform: none;
}

.text-danger{
  color: #B42929
}

/* Colors Ripe start */
.c-gray-dark,
.table-striped .c-gray-dark,
.card .c-gray-dark {
  color: #121212;
  --bs-table-striped-color: #121212;
}
.c-gray-semi-dark,
.table-striped .c-gray-semi-dark,
.card .c-gray-semi-dark{
  color: #181818;
  --bs-table-striped-color: #181818;
}

.c-gray-semi-light,
.table-striped .c-gray-semi-light,
.card .c-gray-semi-light{
  color: #b3b3b3;
  --bs-table-striped-color: #b3b3b3;
}

.c-gray-light,
.table-striped .c-gray-light,
.card .c-gray-light{
  color: #a8a8a8 !important;
  --bs-table-striped-color: #a8a8a8;
}

.c-green,
.table-striped .c-green,
.card .c-green{
  color: #44D62C;
  --bs-table-striped-color: #44D62C;
}

.c-red,
.table-striped .c-red,
.card .c-red{
  color: #B42929;
  --bs-table-striped-color: #B42929;
}

.c-black,
.table-striped .c-black,
.card .c-black{
  color: #000000;
  --bs-table-striped-color: #000000;
}

.c-white,
.table-striped .c-white,
.card .c-white{
  color: #FFFFFF;
  --bs-table-striped-color: #FFFFFF;
}

/* Colors Ripe end */


.auth-card-footer{
  color: #FFFFFF;
  text-align: center;
  font-weight: 300;
}

.auth-card-footer a,
a.auth-card-footer{
  text-decoration: none;
}

.telegram-reg-widget {
  width: min-content;
  margin: 0 auto;
}

input:-webkit-autofill
 {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #A8A8A8 !important;    
    box-shadow: 0 0 0 100px #181818 inset;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  color: #44D62C !important;
  transition: background-color 0s 600000s, color 0s 600000s;
}

.card .green-yellow-gradient,
.green-yellow-gradient {
  color: white;
  margin-bottom: 0px;
  border-radius: 15px;
  border: 1px solid transparent;
  background: linear-gradient(rgba(24, 24, 24, 1), rgba(24, 24, 24, 1)) padding-box,
              linear-gradient(114deg, rgba(61, 174, 55, 0.4) 0%, rgba(250, 233, 0, 0.4) 100%) border-box;
  backdrop-filter: blur(40px);
}

.card .green-yellow-gradient:hover,
.green-yellow-gradient:hover {
  background: linear-gradient(114deg, #44D62C 0%, #FCEC00 100%) padding-box, transparent border-box;
  color: black;
}

.green-yellow-gradient:hover .secondary-text,
.green-yellow-gradient:hover .secondary-text span
{
  color: black !important;
}

.modal-label
{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.modal-header
{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px !important;
}

.qr-code-container canvas{
  border-radius: 15px;
}

.qr-code-container{
  padding: 10px 0 0 0 !important;
  text-align: center;
}

.image-circle-35 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0 !important;
}

.info-btn {
  display: block;
  margin-right: 20px;
  width: 34px;
  height: 34px;
  font-weight: 600;
  padding: 0 !important;
}

.black-ellipse {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(168, 168, 168, 0.2);
  backdrop-filter: blur(6px);
  border-radius: 40px;
  padding: 12px 14px;
}

.green-card{
  background-color: #44D62C !important; 
  border-radius: 20px;
}

::-webkit-scrollbar{
  background: var(--gray_dark, #121212);
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-thumb{
  background: var(--gray_semi_dark, #181818);
  width: 4px;
  height: 6px;
  border-radius: 15px;
}

::-webkit-scrollbar-button{
  display: none;
}

@supports (-moz-appearance:none) {
    html, body {
    scrollbar-color: #181818 #121212; 
    scrollbar-width: thin;
  }
}

.c-red-important
{
  color: #B42929 !important;
  --bs-table-striped-color: #B42929 !important;
}

.c-green-important
{
  color: #44D62C !important;
  --bs-table-striped-color: #44D62C !important;
}

/* убрать сокращение в селекторе сафари */
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  text-overflow: clip !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  text-overflow: clip !important;
}

/* стилизация иконки автозаполнения в сафари */
input::-webkit-credentials-auto-fill-button :hover {
  background-color: #44D62C;
}

input::-webkit-contacts-auto-fill-button :hover {
  background-color: #44D62C;
}

.divider{
  height: 4px;
  padding: 0px 2px;
  margin: 0px 15px;
  border-radius: 50%;
  background: var(--grey_semi_light, #A8A8A8);
}

.divider.dot{
  width: 4px;
}

/**==========================
  Switcher start
==========================**/
.switcher {
  border-radius: 25px;
  display: flex;
  overflow: hidden;
  background: var(--grey_semi_dark, #181818);
}

.switcher .active {
  background-color: #44D62C;
  padding: 3px 7px;
  color: black;
  border-radius: 44px;
}

.switcher .inactive {
  color: grey;
  padding: 3px 7px;
}
/**==========================
  Switcher end
==========================**/

/*=========================
  Custom popover start
=========================*/

.custom-popover .popover .popover-arrow,
.custom-popover .popover-arrow {
  display: none !important;
}

.custom-popover .popover .popover-body,
.custom-popover .popover-body {
  padding: 0 !important;
  color: #FFFFFF !important;
}

.custom-popover .popover,
.custom-popover {
  z-index: 1;
  --bs-popover-zindex: 1;
  width: 70vw !important;
  max-width: 372px !important;
  padding: 20px;
  flex-direction: column !important;
  align-items: flex-start !important;
  border-radius: 15px;
  background: var(--grey_dark, #121212);
  font-style: normal;
  font-size: 16px !important;
  font-family: Museo Sans Cyrl !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  color: #FFFFFF !important;
}

.popover-info {
  display: flex;
  background: transparent;
  border-radius: 57px;
  border: 1px solid var(--white, #FFF);
  width: 15px;
  height: 15px;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  color: var(--white, #FFF);
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 150%; /* 15px */
}


/*=========================
  Custom popover end
=========================*/


/*=========================
  Selector for periods start
=========================*/

.period-select-container {
  text-align: right;
  display: flex;
  justify-content:flex-end;
}

.period-select-block {
  display: flex;
  flex-direction: column;
}

.period-select-title {
  font-size: 16px;
  text-align: left;
  margin-bottom: 4px;
}

.period-select {
  display: inline-flex;
  justify-content: space-evenly;
  flex-direction: row;
  background: transparent;
  padding: 5px 10px;
  border-radius: 40px;
  height: 40px;
  min-width: fit-content;
  width: 100%;
}

.period-option {
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  border-radius: 27px;
  width: 30px;
  margin-right: 5px;
  text-align: center;
  font-weight: 700;
  line-height: 29px;
  font-size: 13px;
  background: #121212;
}

.period-select .period-option:last-child {
  margin-right: 0;
}

.period-active {
  background-color: #44D62C;
  color: #000;
}

.period-option:not(.period-active):hover {
  color: #44D62C;
}

/*=========================
  Selector for periods end
=========================*/

.hidden-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* for Firefox */
.hidden-scrollbar {
  scrollbar-color: transparent transparent !important;
  scrollbar-width: none !important;
}

.mobile-bottom-nav {
  background-color: var(--grey_dark, #121212);
  padding: 15px 20px 30px;
  z-index: 3 !important;
  margin-bottom: -15px;
}

.mobile-bottom-nav .nav-item{
  margin-right: auto;
  margin-left: auto;
}

.input-extended,
.input-extended:focus-visible {
  background-color: transparent;
  border: 0 solid transparent;
  box-shadow: none !important;
  outline: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Блок с подсказкой при переходе по шагам из онбординга */
.onboarding-tip-block {
  display: none;
  position: absolute;
  z-index: 1;
  width: 300px;
  background-color: black;
  padding: 10px;
}

/* Подсвеченный блок, на который указывает подсказка */
.onboarding-highlighted-block {
  position: relative;
  z-index: 1;
  background-color: rgba(217, 217, 217, 0.05);
  border-radius: 15px;
  padding: 10px;
}

.card .card-carousel-index {
  height: 100%;
  cursor: pointer;
  border-radius: 15px !important;
}

.card-carousel-index .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Задаем затемнение */
  z-index: 1; /* Устанавливаем слой над фоновым изображением */
  opacity: 0; /* Начальное значение прозрачности */
  transition: opacity 0.3s; /* Анимация затемнения */
}

.card-carousel-index:hover .overlay {
  opacity: 1; /* При наведении устанавливаем полную непрозрачность */
}

.card-carousel-index .content {
  z-index: 2;
}

.card-carousel-index:hover .index-title {
  color: #44D62C !important;
}

.card-carousel-index .index-short-text {
  opacity: 0; /* Начальное значение прозрачности */
  transition: opacity 0.3s; /* Анимация затемнения */
}

.card-carousel-index:hover .index-short-text {
  opacity: 1;
}

.card .card-featured-prod {
  background: #121212;
  border-radius: 15px;
  min-height: 320px;
  height: 100%;
  padding: 0;
}

.card .card-featured-prod .card-featured-prod-header {
  border-radius: 15px 15px 0px 0px;
  height: 60%;
  padding: 20px;
}

.card .card-featured-prod .card-featured-prod-body {
  padding: 20px;
  height: 40%;
  display: flex;    
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.divider-green {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #44D62C;
}

.social-item {
  border: 1px solid white;
  width: 50px;
  height: 50px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: -1;
  border-radius: 25px; 
  background-color: #000000;
}

.video-cover {
  object-fit: cover !important;
}

.animate-block {
  position: relative;
  padding: 20px;
}

.video-mask {
  border-radius: 23px; 
  overflow: hidden;
}

.artist-card {
  border-radius: 25px; 
  width: 240px;
  min-height: 178px;
  max-height: 196px;
}

.overflow-auto::-webkit-scrollbar {
  display: none; /* Скрыть полосы прокрутки для браузеров на основе движка WebKit (Chrome, Safari) */
}

.overflow-auto {
  -ms-overflow-style: none; /* Скрыть полосы прокрутки для Internet Explorer и Edge */
  scrollbar-width: none; /* Скрыть полосы прокрутки для Firefox */
}

.truncate-text {
  white-space: nowrap; /* Запрещаем перенос слов */
  overflow: hidden; /* Скрываем все, что выходит за пределы блока */
  text-overflow: ellipsis; /* Добавляем троеточие в конце текста */
}

/*=========================
  Custom range input start
=========================*/

.custom-range input[type="range"] {
  --c: #44D62C; /* active color */
  --l: 4px; /* line thickness*/
  --s: 14px; /* thumb size*/
  
  border-radius: 10px;
  height: var(--s); /* needed for Firefox*/
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  cursor: pointer;
  overflow: hidden;
}

.custom-range input[type="range"]:focus-visible,
.custom-range input[type="range"]:hover{
  --p: 25%;
  outline-style: none;
}

/* chromium */
.custom-range [type="range" i]::-webkit-slider-thumb{
  height: var(--s);
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--c); 
  border-image: linear-gradient(90deg,var(--c) 50%, rgba(68, 214, 44, 0.5) 0) 0 1/calc(50% - var(--l)/2) 100vw/0 100vw;
  -webkit-appearance: none;
  appearance: none;
  transition: .3s;
}

/* Firefox */
.custom-range input[type="range"]::-moz-range-thumb {
  height: var(--s);
  width: var(--s);
  background: none;
  border-radius: 50%;
  background: var(--c);
  border-image: linear-gradient(90deg,var(--c) 50%, rgba(68, 214, 44, 0.5) 0) 0 1/calc(50% - var(--l)/2) 100vw/0 100vw;
  -moz-appearance: none;
  appearance: none;
  transition: .3s;
}

/*=========================
  Custom range input end
=========================*/

.btn-gradient {
  background: linear-gradient(113.98deg, #3DAC37 0%, #FCEC00 100%);
  border-width: 0px;
  color: black !important;
}

.btn-gradient.disabled {
  background-color: linear-gradient(113.98deg, #3DAC37 0%, #FCEC00 100%) !important;
  color: black !important;
}

.btn-gradient:hover,
.btn-gradient:active,
.btn-gradient.active,
.btn-gradient:focus-visible {
  background-color: linear-gradient(113.98deg, #3DAC37 0%, #FCEC00 100%) !important;
  color: black !important;
}

.survey-modal .modal-dialog {
  min-width: 300px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.video-wrap {
  padding: 20px;
  border-radius: 25px; 
}

.divider-line {
  height: 1px;
  padding: 0px;
  margin: 0px 5px;
  background: var(--grey_semi_dark, #181818);
}

.carousel-scrollbar::-webkit-scrollbar {
  background: #255720 !important;
  width: 8px !important;
  height: 6px !important;
}

.carousel-scrollbar::-webkit-scrollbar-thumb {
  background: var(--green, #44D62C) !important;
  width: 4px !important;
  height: 6px !important;
  border-radius: 15px !important;
}

.rango-style [type=button], .rango-style [type=reset], .rango-style [type=submit], .rango-style button {
  -webkit-appearance: none !important; /* для перезаписи стилей bootstrap */
  appearance: none !important;
}

.opacity-0 {
  opacity: 0;
}

/**==========================
  FAQ start
==========================**/
.faq-icon {
  width: 26px;
  height: 26px;
}

.faq {
  border-top: 1px solid #A8A8A880;
  transition: all .5s ease;
  max-height: fit-content;
  padding-top: 35px;
}

.faq-dropdown{ 
  transition: all .5s ease;
}

.faq-icon--opened{
  transform: rotate(-90deg);
}

.faq-icon{
  transition: all .5s ease;
}
/*=========================
  FAQ end
=========================*/
