/*====== Table css start ======*/
table {
  border-collapse: separate;
  border-spacing: 0;
}

.table>:not(caption)>*>*{
  background-color: rgba(0, 0, 0, 0) !important;
}

table * {
  border: none;
  border-bottom: 1px solid rgba(168, 168, 168, 0.05);
}

table.adm-table thead{
  border-radius: var(--bs-border-radius) !important;
}

table.adm-table thead tr th:first-child,
.adm-table table thead tr th:first-child {
  border-radius: 40px 0 0 40px;
  border-left: 1px solid rgba(168, 168, 168, 0.2);
}

.adm-table table thead tr th:first-child,
.adm-table table tbody tr > td:first-child {
  padding-left: 20px !important;
}


table.adm-table thead tr th:last-child,
.adm-table table thead tr th:last-child {
  border-radius: 0 40px 40px 0;
  border-right: 1px solid rgba(168, 168, 168, 0.2);
}

table.admin-table thead tr th:first-child,
.admin-table table thead tr th:first-child
{
  border-radius: 10px 0 0px 0px !important;
  padding-left: 30px !important;
}

table.admin-table tbody tr > td:first-child
{
  padding-left: 30px !important;
}

table.admin-table thead tr th:last-child,
.admin-table table thead tr th:last-child
{
  border-radius: 0 10px 0px 0px !important;
} 
table.adm-table thead tr > th,
.adm-table table thead, .adm-table .second-header td, 
.datatable-header {
  font-size: 14px;
  line-height: 120%;
  height: 50px;
  color: white !important;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(20px);
  vertical-align: middle;
  border-left: none;
  border-right: none;
}

table.adm-table tr:nth-child(even)
{
  --bs-table-bg: rgba(255, 255, 255, 0.02) !important; 
}
table.adm-table tr:nth-child(odd)
{
  --bs-table-striped-bg: transparent;
}

table.adm-table thead tr > th,
.adm-table table thead tr > th {
  padding: 10px 0 10px 10px !important; 
  font-weight: 400;
  border-top: 1px rgba(168, 168, 168, 0.2) solid;
  border-bottom: 1px rgba(168, 168, 168, 0.2) solid;
}

table.adm-table thead tr th:last-child,
table.adm-table tbody tr td:last-child,
.adm-table table thead tr th:last-child,
.adm-table table tbody tr td:last-child {
  padding: 8px 20px !important;
}

table.adm-table tbody tr > td,
table.adm-table tbody tr td.expand-column,
.adm-table table tbody tr > td {
  font-size: 14px;
  vertical-align: middle;
  height: 50px;
  padding: 16px 0 16px 10px !important;
  color:#fff;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: #fff;
}

table.adm-table tbody tr,
.adm-table table.default-table-view tbody > tr {
  border-style: none;
}

table.adm-table.default-table-view tbody tr,
.adm-table table.default-table-view tbody > tr {
  border-style: none;
}

table.adm-table thead tr th div {
  padding: 0 !important;
}

.table-scrollable tbody {
  display:block;
  overflow:auto;
}

.table-scrollable thead, 
.table-scrollable tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
}

/*
  выравнивание последнего столбца по правому краю
*/

.second-header td{
  font-weight: 600;
}

/*====== Table css end ======*/

/*====== Filter css start ======*/

/*====== ng-select css start ======*/
.custom-filter .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items{
  margin-top: 8px;
}

.custom-filter .ng-dropdown-panel.ng-select-top,
.custom-filter .ng-dropdown-panel.ng-select-bottom {
  border-bottom: none;  
  margin-bottom: 0px;
  color: #fff;
}

.ng-dropdown-panel.ng-select-top{
  box-shadow: none !important;
}


.custom-filter .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items{
  margin-bottom: 8px;
}

.custom-filter .ng-option {
  padding: 12px 14px !important;
}

.custom-filter .ng-select {
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(20px);
  color: #A8A8A8;
  border-radius: 40px;
  padding: 0 !important;
}

.custom-filter .ng-select .ng-select-container {
  border-radius: 40px;
  border: 1px solid rgba(168, 168, 168, 0.2);
  padding: 12px 14px;
}

.ng-option-marked{
  color: #A8A8A8 !important;
}

.custom-filter .ng-dropdown-panel .ng-dropdown-panel-items{
  max-height: 400px !important;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.custom-filter .ng-arrow {
  display: none;
}

.theme-form .ng-arrow-wrapper, .custom-filter .ng-arrow-wrapper {
  height: 20px;
  width: 20px;
  background-image: url('../images/ripe/icons/arrow_down.svg');
  background-repeat: no-repeat;
}

.hide-arrow .ng-arrow-wrapper {
  background-image: none;
}

.custom-filter .ng-select-opened  .ng-arrow-wrapper { 
  background-image: url('../images/ripe/icons/arrow_up.svg');
  top: 6px;
  left: 3px;  
}

.ng-select.ng-select-opened>.ng-select-container {
  background: transparent !important;
}

/*====== ng-select css end ======*/

/* .custom-filter {
  font-family: 'GOST UI 2';
  font-style: normal;
  font-weight: 400;
  font-size: 15px; 
} */



.custom-filter button{
  padding: 8px 15px;
}

.custom-filter label{
  color: white;
  margin-bottom: 5px;
  font-size: 16px;
}

.custom-filter .inputField {
  height: auto;
  display: flex;
  padding: 9px 13px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(168, 168, 168, 0.2);
  backdrop-filter: blur(6px);
}
.custom-filter .inputField input[type=text] {
  border: rgba(168, 168, 168, 0.2) !important;
  background-color: transparent;
  width: 100%;
  color: white;
}

.custom-filter .inputField input[type=text]:focus {
  outline: none !important;
}

.custom-filter .selector{
  margin-bottom: 15px;
}

.alignment-lower-right{
  display: grid;
  justify-content: end;
  align-items: end;
}

.document-filter .ng-option {
  padding: 8px 20px !important;
}

.document-filter .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items {
  margin-top: 8px;
  margin-bottom: 2px;
}
/*====== Filter css end ======*/


/* Short address start */
.middleEllipsis {
  /* margin: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.middleEllipsis > .start {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
}
.middleEllipsis > .end {
  white-space: nowrap;
  flex-basis: content;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: right;
  overflow: hidden;
  direction: rtl;
}
/* Short address start */

.img-bord-radius10{
  overflow: hidden;
  border-radius: 10px !important;
  margin: 0 0 20px;
}

.img-bord-radius10 img{
  padding: 0 !important;
}

.card-h-3{
  height: 3rem;
}

.card-text{
  font-size: 15px;
  line-height: 22.5px;
}

img.show-cursor:hover, .show-cursor:hover{
  cursor: pointer;
}

.datatable-header{
  padding: 15px 15px 20px;
  border-radius: 10px 10px 0 0;
}

.datatable-header-cell-label{
  font-size: 16px;
  font-weight: bold;
}

.datatable-row-odd{
  background-color: rgb(247, 247, 247);
}

.datatable-body-row{
  padding: 20px 15px;
  font-size: 15px;
  width: max-content !important;
}

.internal-table {
  padding: 0 !important;
  white-space: nowrap;
}

table.last-column-left tbody tr td:last-child,
table.last-column-left thead tr th:last-child {
  text-align: left!important;
  color: #fff!important;
}

.v-align-top td, .v-align-top tr{
  vertical-align: top !important;
}

.img-h-23{
  height: 23px;
}

.img-h-28{
  height: 28px;
}
