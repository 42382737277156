/* You can add global styles to this file, and also import other style files */
@import './assets/icons/flag-icon/flag-icon-base';
@import './assets/icons/flag-icon/flag-icon-more';
@import './assets/icons/flag-icon/flag-icon-list';
@import "./assets/common_styles/bs-datepicker.style.scss";
@import './assets/common_styles/ng-select.style.scss'; //ng-select custom 
@import './assets/common_styles/carousel.style.scss';

@import './assets/css/ngx-charts.css';
@import './assets/scss/_generic.scss';

@import './assets/css/color-2.css';
@import './assets/css/table.css';